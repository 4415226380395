<!--
 * @Description: 基本资料
 * @Author: ChenXueLin
 * @Date: 2021-10-18 15:45:42
 * @LastEditTime: 2022-07-01 16:41:09
 * @LastEditors: ChenXueLin
-->
<!-- 基本信息 -->
<template>
  <div v-loading="loading" :element-loading-background="loadingBackground">
    <!-- 基本信息 start -->
    <div class="button-content clearfix">
      <el-button type="primary" @click="openUpdateDialog"
        >编辑基本信息</el-button
      >
      <el-button
        type="primary"
        @click="openUpdateSceneEquipInfoDialog"
        v-has="71"
        >编辑设备信息</el-button
      >
    </div>
    <template>
      <div class="baseInfo-box">
        <div class="edit-title">基本信息</div>
        <el-form label-width="140px" label-position="right">
          <el-row>
            <el-col :span="6">
              <el-form-item label="场景ID">
                {{ basicInfo.sceneId }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="场景名称">
                {{ basicInfo.sceneName }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="系统场景名称">
                {{ basicInfo.regName }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="自定义场景名称">
                {{ basicInfo.customName }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="场景类型">
                {{ basicInfo.sceneTypeName }}
              </el-form-item>
            </el-col>
            <el-col
              :span="6"
              v-if="basicInfo.sceneType === 1 || basicInfo.sceneType === 4"
            >
              <el-form-item label="车牌颜色">
                {{ basicInfo.vehicleColor }}
              </el-form-item>
            </el-col>
            <el-col
              :span="6"
              v-if="basicInfo.sceneType === 2 || basicInfo.sceneType === 3"
            >
              <el-form-item label="地址">
                {{ basicInfo.address }}
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="客户名称">
                {{ basicInfo.corpName }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户属性">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="basicInfo.attrName"
                  placement="left"
                >
                  <div class="ellipsis">
                    {{ basicInfo.attrName }}
                  </div>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="业务员">
                {{ basicInfo.salesman }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="业务员电话">
                {{ basicInfo.salesmanPhone }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="创建人">
                {{ basicInfo.createdBy }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="创建时间">
                {{ basicInfo.createdTimeStr }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注">
                <div class="auto-wrap-content">{{ basicInfo.remark }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </template>

    <!-- 基本信息 end -->
    <!-- 属性信息 start -->
    <template>
      <div class="baseInfo-box">
        <div class="edit-title">属性信息</div>
        <section class="table-wrapper">
          <el-table border :data="attrInfo" highlight-current-row>
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in attrColumnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
            </el-table-column>
          </el-table>
        </section>
      </div>
    </template>
    <!-- 属性信息 end -->
    <!-- 已安装设备 start -->
    <template>
      <div class="baseInfo-box">
        <div class="edit-title">已安装设备</div>
        <section class="table-wrapper">
          <el-table
            border
            height="300px"
            :data="installedEquipList"
            highlight-current-row
          >
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in deviceColumnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row }">
                <span
                  v-if="column.fieldName === 'installGroup' && row.installGroup"
                >
                  {{ row[column.fieldName].secondClassName }}
                </span>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              align="center"
              label="操作"
              width="120"
            >
              <template slot-scope="scope">
                <e6-td-operate
                  :data="getOperateList()"
                  @command="handleOperate($event, scope.row)"
                ></e6-td-operate>
              </template>
            </el-table-column>
          </el-table>
        </section>
      </div>
    </template>
    <!-- 已安装设备 end -->
    <scenelnfo-update
      ref="scenelnfoUpdate"
      v-if="scenelnfoUpdateVisible"
      @update="updateBaseInfo"
    ></scenelnfo-update>
    <scene-quiplnfo-update
      ref="sceneQuiplnfoUpdate"
      v-if="sceneQuiplnfoUpdateVisible"
      @update="getListInstalled"
    ></scene-quiplnfo-update>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getSceneBasic, getListInstalled } from "@/api";
import { printError } from "@/utils/util";

import scenelnfoUpdate from "@/views/OMS/equipSceneManage/sceneInfoManage/scenelnfoUpdate/scenelnfoUpdate";
import sceneQuiplnfoUpdate from "@/views/OMS/equipSceneManage/sceneInfoManage/scenelnfoUpdate/sceneQuiplnfoUpdate";
export default {
  name: "sceneInfoDetail",
  mixins: [base, listPage, listPageReszie],
  components: { scenelnfoUpdate, sceneQuiplnfoUpdate },
  data() {
    return {
      scenelnfoUpdateVisible: false, // 是否渲染修改场景名称弹窗
      sceneQuiplnfoUpdateVisible: false, // 是否渲染修改设备中心识别码弹窗
      basicInfo: {
        sceneId: "", //场景ID
        sceneName: "", //场景名称
        regName: "", //系统场景名称
        customName: "", //自定义场景名称
        sceneTypeName: "", //场景类型
        vehicleColor: "", //车牌颜色
        corpName: "", //客户名称
        attrName: "", //客户属性
        salesman: "", //业务员
        salesmanPhone: "", //业务员电话
        createdBy: "", //创建人
        remark: "", //备注
        createdTimeStr: "" //创建时间
      },
      vehicleAttrColumn: [
        {
          fieldName: "vin",
          display: true,
          fieldLabel: "车架号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "brandName",
          display: true,
          fieldLabel: "车辆品牌",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "vehicleTypeName",
          display: true,
          fieldLabel: "车型分类",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "overallLength",
          display: true,
          fieldLabel: "车体长(CM)",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "overallWidth",
          display: true,
          fieldLabel: "车体宽(CM)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "overallHeight",
          display: true,
          fieldLabel: "车体高(CM)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        }
      ],
      fixedSceneAttrColumn: [
        {
          fieldName: "overallLength",
          display: true,
          fieldLabel: "长(CM)",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "overallWidth",
          display: true,
          fieldLabel: "宽(CM)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "overallHeight",
          display: true,
          fieldLabel: "高(CM)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        }
      ],
      //属性信息表头
      attrInfo: [], // 属性信息表格数据
      deviceColumnData: [
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "virtualTypeName",
          display: true,
          fieldLabel: "商品类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installGroup",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipStatusName",
          display: true,
          fieldLabel: "设备状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installTimeStr",
          display: true,
          fieldLabel: "安装时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "realNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //已安装设备表头
      installedEquipList: [] // 表格数据
    };
  },
  computed: {
    //基本信息
    attrColumnData() {
      switch (this.basicInfo.sceneType) {
        case 1:
        case 4:
          return this.vehicleAttrColumn;
        case 2:
        case 3:
          return this.fixedSceneAttrColumn;
        default:
          return [];
      }
    }
  },
  watch: {},
  methods: {
    init(vehicleId) {
      this.vehicleId = vehicleId;
      if (this.vehicleId) {
        this.getListInstalled();
        this.updateBaseInfo();
      }
    },
    // 打开修改信息弹窗
    openUpdateDialog() {
      this.scenelnfoUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.scenelnfoUpdate.init(this.basicInfo);
      });
    },
    // 打开修改设备信息弹窗
    openUpdateSceneEquipInfoDialog() {
      let handleTarget = this.installedEquipList.find(
        item => item.virtualType === 1
      );
      if (!handleTarget) {
        this.$message.error("安装设备列表不存在主机");
        return;
      }
      console.log(handleTarget);
      this.sceneQuiplnfoUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.sceneQuiplnfoUpdate.init(handleTarget);
      });
    },
    //获取安装列表
    async getListInstalled() {
      try {
        this.loading = true;
        let queryList = [getListInstalled({ id: this.vehicleId })];
        let [installListRes] = await Promise.all(queryList);
        this.installedEquipList = this.getFreezeResponse(installListRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 更新基础信息，修改场景名完成后调用
    async updateBaseInfo() {
      let vehicleId = this.vehicleId;
      try {
        this.loading = true;
        let res = await getSceneBasic({ id: vehicleId });
        this.basicInfo = res.data.basicInfo;
        this.basicInfo.vehicleId = vehicleId;
        this.attrInfo = [res.data.attrInfo];
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row = {}) {
      if (val.id == 1) {
        this.routerPush({
          name: "equipSceneManage/equipDetail",
          params: {
            refresh: true,
            origin: this.$route.name,
            equipId: row.equipId
          }
        });
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.edit-title {
  margin-bottom: 15px;
}
.table-wrapper {
  margin-bottom: 15px;
}
.button-content {
  button {
    float: right;
    margin: 0 20px 20px 0;
  }
  margin: 15px 10px;
  box-sizing: border-box;
}
</style>
