<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2022-01-20 09:27:26
 * @LastEditTime: 2022-08-25 17:21:13
 * @LastEditors: LiangYiNing
-->
<template>
  <el-dialog
    v-dialogDrag
    title="编辑设备信息"
    :visible.sync="visible"
    width="620px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-customer-dialog"
  >
    <el-form ref="form" label-width="130px" :rules="rules" :model="form">
      <el-form-item label="中心识别码" prop="commids">
        <el-input
          v-model.trim="form.commids"
          maxlength="32"
          placeholder="请输入中心识别码"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { quiplnfoUpdate } from "@/api";
import { printError } from "@/utils/util";
import { commidsValid } from "@/utils/validate.js";
export default {
  name: "SceneQuiplnfoUpdate",
  mixins: [base],
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        terminalId: "",
        commids: ""
      }
    };
  },
  computed: {
    rules() {
      return {
        commids: [
          {
            required: true,
            message: "请输入中心识别码",
            trigger: ["blur", "change"]
          },
          { validator: commidsValid.validator(), trigger: ["blur", "change"] }
        ]
      };
    }
  },
  created() {},
  methods: {
    init(data = {}) {
      this.visible = true;
      this.form = {
        terminalId: data.terminalId,
        commids: data.commids
      };
    },
    handleSubmit() {
      this.$refs.form.validate().then(() => {
        this.quiplnfoUpdate();
      });
    },
    async quiplnfoUpdate() {
      try {
        this.loading = true;
        let res = await quiplnfoUpdate(this.form);
        if (res.code === "OK") {
          this.$message.success("修改成功");
          this.$emit("update");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.visible = false;
      this.form = {
        commids: "",
        terminalId: ""
      };
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input,
    .el-textarea__inner {
      width: 350px;
    }
  }
}
</style>
