<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2022-01-20 09:27:26
 * @LastEditTime: 2022-08-25 15:36:08
 * @LastEditors: LiangYiNing
-->
<template>
  <el-dialog
    v-dialogDrag
    title="编辑场景名称"
    :visible.sync="visible"
    width="620px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-customer-dialog"
  >
    <el-form ref="form" label-width="130px" :rules="rules" :model="form">
      <el-form-item label="场景名称" prop="sceneName">
        <el-input
          v-model.trim="form.sceneName"
          maxlength="60"
          placeholder="场景名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="系统场景名称" prop="regName">
        <el-input
          v-model.trim="form.regName"
          placeholder="系统场景名称"
          maxlength="60"
        ></el-input>
      </el-form-item>
      <el-form-item label="自定义场景名称" prop="customName">
        <el-input
          v-model.trim="form.customName"
          placeholder="自定义场景名称"
          maxlength="32"
        ></el-input>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model.trim="form.remark"
          rows="3"
          placeholder="备注"
          maxlength="256"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { updateSceneInfo } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "ScenelnfoUpdate",
  mixins: [base],
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        vehicleId: "",
        sceneName: "",
        regName: "",
        customName: "",
        remark: ""
      }
    };
  },
  computed: {
    rules() {
      const tirmString = (rule, value, callback) => {
        const reg = /\s/;
        console.log(value, reg.test(value));
        if (reg.test(value)) {
          callback(new Error("不能输入空格"));
          return;
        }
        callback();
      };
      return {
        sceneName: [
          {
            required: true,
            message: "请输入场景名称",
            trigger: ["blur", "change"]
          },
          { validator: tirmString, trigger: ["blur", "change"] }
        ],
        regName: [
          {
            required: true,
            message: "请输入系统场景名称",
            trigger: ["blur", "change"]
          },
          { validator: tirmString, trigger: ["blur", "change"] }
        ],
        customName: [
          {
            required: true,
            message: "请输入自定义场景名称",
            trigger: ["blur", "change"]
          },
          { validator: tirmString, trigger: ["blur", "change"] }
        ]
      };
    }
  },
  created() {},
  methods: {
    init(data = {}) {
      this.visible = true;
      this.form = {
        vehicleId: data.vehicleId,
        sceneName: data.sceneName,
        regName: data.regName,
        customName: data.customName,
        remark: data.remark
      };
    },
    handleSubmit() {
      this.$refs.form.validate().then(() => {
        this.updateSceneInfo();
      });
    },
    async updateSceneInfo() {
      try {
        this.loading = true;
        let res = await updateSceneInfo(this.form);
        if (res.code === "OK") {
          this.$message.success("修改成功");
          this.$emit("update");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.visible = false;
      this.form = {
        vehicleId: "",
        sceneName: "",
        regName: "",
        customName: "",
        remark: ""
      };
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input,
    .el-textarea__inner {
      width: 350px;
    }
  }
}
</style>
